<template>
  <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
    
    <el-row>
      <el-col :span="12">
        <div class="Alltxt">{{ $t("common.allTanks") }}</div>
      </el-col>
      <el-col :span="12" class="text-right pr-2">
        <el-input
          :placeholder="$t('btn.SearchTankNumber')"
          class="Tankinput"
          size="small"
          v-model="qualityinput"
          @keyup.enter.native="qualityinputval(qualityinput)"
        >
          <span slot="suffix" @click="qualityinputval(qualityinput)">
            <svg-icon iconClass="search" />
          </span>
        </el-input>
      </el-col>
    </el-row>

    <div v-loading="listLoadig" class="content overflow-x-hidden p-3 mobile:p-0">
      <div class="grid grid-cols-6 gap-2">
      <!-- <div class="grid grid-cols-5 gap-2"> -->
        <!-- style="float:left;margin:10px" -->
        <div class="item mobile:m-2.5 p-4 mobile:p-2" v-for="(item, index) in list" :key="index">
          <div class="Tanname  text-xl mobile:text-base">{{ item.tankNumber }}</div>
          <!-- <div class="Updated">Last Updated:</div>
        <div class="time">17-08-2022 13:57:05</div> -->
          <div class="Uploaded mt-6 mobile:mt-1">COQ Uploaded:</div>
          <div
            class="img cursor-pointer"
            v-if="item.tankFile"
            @click="handlePreviewPdfClick(item)"
          >
            <span>
              <svg-icon icon-class="pdf" />
            </span>
            <div class="truncate">
              <p class="name" :title="item.tankFile.originalName">{{ item.tankFile.originalName }}</p>
              <p class="time">{{ item.tankFile.createTime }}</p>
            </div>
          </div>
          <div class="Noimg" v-else>No Data</div>
          <div class="Tankbut">
            <el-button @click="handleUploadClick(item)" size="small" plain>{{
              $t("btn.UploadCOQ")
            }}</el-button>
          </div>
        </div>
      </div>
      <div v-if="listLoadig" style="height: 400px; width: 100%"></div>
      <no-data v-if="!listLoadig && !list.length" />
      <div class="flex justify-end w-full">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          @pagination="getList"
        ></pagination>
      </div>
    </div>

    <pdf-view
      title="Certificate Of Quality"
      :pdfUrl="operationData.src"
      v-model="showPdfView"
    />
    <input
      type="file"
      ref="inputFile"
      accept=".pdf"
      :multiple="true"
      @change="handleFileChange"
      style="opacity: 0; position: fixed; z-index: -1"
    />
  </div>
</template>

<script>
import {
  getMyCompanyTank,
  singleleUploadFile,
  tanksAddFiles,
} from "@/services";
import { getServerUrl } from "@/utils/getServerUrl";
import PdfView from "@/components/common/pdf-view.vue";
import NoData from "@/components/common/no-data.vue";
export default {
  name: "quality",
  components: { PdfView, NoData },
  data() {
    return {
      dialogFormVisible: false,
      AssignListFormVisible: false,
      qualityinput: "",
      Nameimg: "",
      list: [],
      listLoadig: false,
      showPdfView: false,
      operationData: {
        src: "",
      },
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handlesuccess(response, file) {
      this.Nameimg = file.name;
    },
    // input框
    qualityinputval(value) {
      this.getList();
      this.qualityinput = "";
    },
    getList() {
      this.listLoadig = true;
      let data = {
        ...this.pageParams,
      };
      if (this.qualityinput) {
        data["tankNumber"] = this.qualityinput;
      }
      getMyCompanyTank(data)
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
        })
        .finally(() => (this.listLoadig = false));
    },
    handlePreviewPdfClick(item) {
      this.operationData = {
        src: getServerUrl() + item.tankFile.url,
      };
      this.showPdfView = true;
    },
    handleUploadClick(item) {
      this.$refs.inputFile.click();
      this.operationData.tankNumber = item.tankNumber;
    },
    handleFileChange(event) {
      let files = event.target.files;
      if (files.length) {
        let arrFiles = [...files];
        let requests = [];
        arrFiles.forEach((file) => {
          requests.push(singleleUploadFile(file));
        });
        Promise.all(requests).then((res) => {
          // 上传附件
          let datas = [];
          res.forEach((item) => {
            datas.push({
              url: item.fileName,
              tankNumber: this.operationData.tankNumber,
              originalName: item.originalFilename,
            });
          });
          tanksAddFiles(datas).then(() => {
            this.$message.success(this.$t("status.success"));
            this.getList();
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Tankinput {
  width: 275px;
}

.Alltxt {
  box-sizing: border-box;
  padding: 0px 0px 0px 22px;
  background-color: #ffffff;
  font-weight: bold;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
}

.el-button--default {
  color: #fff;
  background-color: #008CDB;
}
.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}
.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}

.content {
  // height: calc(90vh - 80px - 32 * 2px - 26px);
  overflow-y: scroll;
  width: 100%;
  background: #fcfdff;
  // padding: 12px;
  .item {
    // min-width: 220px;
    // max-width:320px;
    // width: 325px;
    background: #fff;
    border: 1px solid rgb(213, 221, 237);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
    // padding: 18px;
    // max-height: 237px;

    .img {
      display: flex;
      // justify-content: center;
      align-items: center;
      height: 65px;
      text-align: center;
      margin-bottom: 15px;
      color: #008CDB;
      background-color: #fff7f2;
      > div {
        line-height: 1;
      }
      p {
        text-align: left;
      }
      .name {
        font-weight: 600;
        font-size: 14px;
      }
      .time {
        font-weight: 400;
        font-size: 12px;
        margin-top: 5px;
      }
      svg {
        margin: 0 10px;
        width: 40px;
        height: 50px;
      }
    }
    .Noimg {
      height: 65px;
      text-align: center;
      line-height: 65px;
      margin-bottom: 15px;
      background-color: #f4f8fb;
      color: #82889c;
      font-size: 12px;
    }
  }
  .Tanname {
    display: flex;
    justify-content: space-between;
    // font-size: 28px;
    font-weight: 500;
  }
  .Updated {
    margin-top: 21px;
    font-size: 12px;
  }
  .time {
    margin-top: 2px;
    font-weight: 700;
    font-size: 20px;
  }
  .Uploaded {
    // margin-top: 26px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .Noimg {
    height: 65px;
    text-align: center;
    line-height: 65px;
    margin-bottom: 15px;
    background-color: #f4f8fb;
  }
  .img {
    height: 65px;
    text-align: center;
    line-height: 65px;
    margin-bottom: 15px;
    color: #008CDB;
    background-color: #fff7f2;
  }
  .Tankbut {
    text-align: center;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #fcfdff;
  padding-top: 40px;
  padding-bottom: 0;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
</style>
