<template>
  <div :style="{ height: height + 'px' }" class="noDataContainer">
    <span>{{ $t("ifm.nd") }}</span>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 400,
    },
  },
};
</script>

<style scoped>
.noDataContainer {
  /* background: #ffffff; */
  /* box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1); */
  border-radius: 2px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #82889c;
  font-weight: 700;
  font-size: 16px;
}
</style>